<template>
  <div>
    <md-card v-if="uploading">
      <md-card-header>
        <h4
          class="title"
          style="font-weight:bold"
        >
          Upload Form
        </h4>

        <ul class="subtitle">
          <li>
            Files size cannot be greater than 5MB. You can reduce the file size <a
              href="https://www.adobe.com/ie/acrobat/online/compress-pdf.html"
              target="_blank"
            >HERE</a>
          </li>
          <li>
            Files extension must be <b>.pdf / .PDF</b>
          </li>
        </ul>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item">
            <form-text
              v-model="form.title"
              name="title"
              icon="description"
              label="File Title"
            />
          </div>
          <div class="md-layout-item pt-3">
            <form-uploader
              v-model="form.file"
              :max-size="5*1024"
              @error="onFileError"
            >
              <md-button
                class="md-info md-sm w-100"
              >
                Choose File
              </md-button>
            </form-uploader>
          </div>
          <div class="md-layout-item md-size-100">
            <form-text
              v-model="form.marketing_remark"
              name="remark"
              icon="description"
              label="Receipt Remark"
            />
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item">
            <md-button
              class="md-primary md-sm w-100"
              @click="uploadForm"
            >
              <md-icon class="material-icons">
                save
              </md-icon>
              Upload
            </md-button>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item">
            <md-button
              class="md-danger md-sm w-100"
              @click="cancelUpload"
            >
              <md-icon class="material-icons">
                close
              </md-icon>
              Cancel Upload
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>

    <md-card v-if="linking">
      <md-card-header>
        <h4
          class="title"
          style="font-weight:bold"
        >
          Link to File Form
        </h4>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item">
            <form-select
              v-model="linkFileForm.file_id"
              :options="files"
              name="file"
              icon="attach_file"
              label="File to Link"
            />
          </div>
          <div class="md-layout-item md-size-100">
            <form-text
              v-model="linkFileForm.marketing_remark"
              name="remark"
              icon="description"
              label="Receipt Remark"
            />
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item">
            <md-button
              class="md-primary md-sm w-100"
              @click="linkFile"
            >
              <md-icon class="material-icons">
                save
              </md-icon>
              Link
            </md-button>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item">
            <md-button
              class="md-danger md-sm w-100"
              @click="cancelLinking"
            >
              <md-icon class="material-icons">
                close
              </md-icon>
              Cancel Linking
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>

    <md-card>
      <md-card-header>
        <md-button
          class="float-right md-primary md-sm"
          @click="toFinancial(false)"
          @click.middle="toFinancial(true)"
        >
          Go To Financial Profile
        </md-button>
        <md-button
          class="float-right md-info md-sm mr-2"
          @click="toResume(false)"
          @click.middle="toResume(true)"
        >
          Go To Student Profile
        </md-button>

        <h5 class="category">
          <b>Total: {{ total | currency('€') }} - <span style="color: red">
            Unpaid: {{ unpaid | currency('€') }}</span></b>
        </h5>

        <StudentData
          :name="studentData.name"
          :email="studentData.email"
          :number="studentData.number"
          :agent="studentData.agent"
        />
      </md-card-header>
      <md-card-content>
        <vue-table
          v-if="vTable.headers"
          ref="vtable"
          :values="vTable.values"
          :headers="vTable.headers"
          :actions="vTable.actions"
          :options="{
            tableClass:'table'
          }"
          :search="false"
          @changed="getPayments"
          @onDownload="onDownload"
          @onDelete="onDelete"
          @onUpload="onUpload"
          @onLinkFile="onLinkFile"
          @onUnlinkFile="onUnlinkFile"
          @onApprovePayment="onApprovePayment"
          @onDenyPayment="onDenyPayment"
          @onShowPaymentDetails="onShowPaymentDetails"
          @onCreateButton="onCreateButton"
          @onPreview="onPreview"
        />
      </md-card-content>
      <md-card-actions>
        <router-link
          :to="{name:'Packages'}"
          tag="md-button"
          class="md-primary"
        >
          Return
        </router-link>
      </md-card-actions>
    </md-card>

    <approve-payment
      v-if="approvePaymentDetails"
      :item="approvePaymentDetails"
      @close="approvePaymentDetails = null"
      @onSave="$refs.vtable.init"
      @onDownload="onDownload(approvePaymentDetails)"
    />

    <payment-details
      v-if="selPaymentDetails"
      :item="selPaymentDetails"
      @close="selPaymentDetails = null"
      @onDownload="onDownload"
    />
  </div>
</template>

<script>
/* eslint-disable camelcase */
import VueTable from '@/components/Tables/VueTable';
import { FormUploader, FormText, FormSelect } from '@/components/Inputs';
import StudentData from '@/components/StudentData.vue';
import ApprovePayment from '@/pages/Dashboard/Financial/ApprovePayment.vue';
import PaymentDetails from '@/pages/Dashboard/Financial/PaymentDetails.vue';
import downloadFile from '@/utils/downloadFile';

export default {
  components: {
    VueTable,
    FormUploader,
    FormText,
    FormSelect,
    StudentData,
    ApprovePayment,
    PaymentDetails,
  },
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'payment_date',
            mask: 'scheduled date',
            dateFormat: true,
            sortable: true,
          },
          {
            title: 'package_payment_id',
            mask: 'Payment Id',
            sortable: true,
          },
          {
            title: 'total',
            mask: 'amount',
            sortable: true,
            pre: '€',
          },
          {
            title: 'status',
            sortable: true,
          },
          {
            title: 'marketing_remark',
            mask: 'sales remark',
            sortable: false,
          },
          {
            title: 'file.title',
            mask: 'title of uploaded file',
            sortable: false,
          },
          {
            title: 'file.uploader.name',
            mask: 'Uploaded by',
            sortable: false,
          },
        ],
        values: {},
        actions: [
          {
            buttonClass: 'md-success',
            tooltip: 'Approve Payment',
            callback: 'onApprovePayment',
            icon: 'thumb_up_alt',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Deny Payment',
            callback: 'onDenyPayment',
            icon: 'thumb_down_alt',
          },
          {
            buttonClass: 'md-info',
            tooltip: 'Show Details',
            callback: 'onShowPaymentDetails',
            icon: 'search',
          },
          {
            buttonClass: 'md-info',
            tooltip: 'Preview Proof of Payment',
            callback: 'onPreview',
            icon: 'visibility',
          },
          {
            buttonClass: 'md-info',
            tooltip: 'Upload File',
            callback: 'onUpload',
            icon: 'cloud_upload',
          },
          {
            buttonClass: 'md-info',
            tooltip: 'Download File',
            callback: 'onDownload',
            icon: 'cloud_download',
          },
          {
            buttonClass: 'md-success',
            tooltip: 'Link Payment to File',
            callback: 'onLinkFile',
            icon: 'link',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Unlink File from Payment',
            callback: 'onUnlinkFile',
            icon: 'link_off',
          },
          {
            buttonClass: 'md-primary',
            tooltip: 'Send Stripe Button',
            callback: 'onCreateButton',
            icon: 'mail',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete File',
            callback: 'onDelete',
            icon: 'close',
          },
        ],
      },
      form: {
        package_payment_id: null,
        file_type_id: 3,
        student_id: null,
        title: null,
        file: null,
        marketing_remark: null,
      },
      uploading: false,
      // To link a file to the payment
      linkFileForm: {
        package_payment_id: null,
        file_id: null,
        marketing_remark: null,
      },
      linking: false,
      files: null,
      approvePaymentDetails: null,
      selPaymentDetails: null,
    };
  },
  computed: {
    total() {
      if (!this.vTable.values.data) return 0;
      return this.vTable.values.data.reduce((a, b) => a + parseFloat(b.total), 0).toFixed(2);
    },
    unpaid() {
      if (!this.vTable.values.data) return 0;
      return this.vTable.values.data.reduce((a, b) => {
        if (b.status !== 'Paid') {
          return a + parseFloat(b.total);
        }
        return a;
      }, 0).toFixed(2);
    },
    studentData() {
      if (this.vTable.values.data && this.vTable.values.data.length) {
        const {
          student_number,
          full_name,
          email,
        } = this.vTable.values.data[0].package.programme.student;

        return {
          number: student_number,
          name: full_name,
          email,
          agent: this.vTable.values.data[0].package.programme.agent?.name,
        };
      }
      return {};
    },
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    toFinancial(isMiddleClick) {
      const studId = this.vTable.values.data[0].package.programme.student_id;
      this.openLink({
        name: 'StudentFinancial',
        params: { id: studId },
      }, isMiddleClick);
    },
    toResume(isMiddleClick) {
      const studId = this.vTable.values.data[0].package.programme.student_id;
      this.openLink({
        name: 'StudentResume',
        params: { id: studId },
      }, isMiddleClick);
    },
    getPayments(params) {
      this.request(this.$API.PACKAGES + this.$route.params.id + '/payments'.paginableUrl(params), 'get', {
        with_agent: true,
      }, ({ data }) => {
        const aux = data;
        aux.data.map((x) => {
          const a = x;
          a.onPreview = a.proof_of_payment_id !== null;
          a.onDownload = a.proof_of_payment_id !== null;
          a.onCreateButton = this.onlinePaymentsActive && ['Unpaid', 'Overdue'].includes(x.status);
          a.onDelete = a.proof_of_payment_id !== null;
          a.onUpload = a.proof_of_payment_id === null;
          a.onLinkFile = a.proof_of_payment_id === null;
          a.onUnlinkFile = a.proof_of_payment_id !== null;
          a.onDenyPayment = ['Unpaid', 'Overdue'].includes(x.status);
          a.onApprovePayment = ['Unpaid', 'Overdue'].includes(x.status);
          return a;
        });
        this.vTable.values = aux;
      });
    },
    onDelete(item) {
      this.fireConfirm(
        'Deleting File',
        'Are you sure you want to delete this File?',
      ).then(() => {
        const studentId = item.package.programme.student_id;
        this.request(`${this.$API.STUDENTS + studentId}/${this.$API.FILES}${item.proof_of_payment_id}`, 'delete', null, () => {
          this.fireSuccess('File deleted successfully!');
          this.$refs.vtable.init();
        });
      });
    },
    onPreview(item) {
      this.openFileUrl(item.file.file_id);
    },
    onDownload(item) {
      // console.log(item);
      const studentId = item.package.programme.student_id;

      this.request(`${this.$API.STUDENTS + studentId}/${this.$API.FILES}${item.proof_of_payment_id}`, 'download', null, ({ data }) => {
        const filename = item.file.path.split('/')[item.file.path.split('/').length - 1];
        downloadFile(data, filename);
      });
    },
    onUpload(item) {
      this.form.package_payment_id = item.package_payment_id;
      this.form.student_id = item.package.programme.student_id;
      this.uploading = true;
    },
    onFileError(error) {
      this.fireError(error);
    },
    onCreateButton(item) {
      this.request(`payment/${item.package_payment_id}/send_button`, 'post', {}, () => {
        this.fireSuccess('Button created succesfully, an Email has been sent to the student.');
      });
    },
    uploadForm() {
      const formData = new FormData();
      formData.append('file', this.form.file);
      formData.append('title', this.form.title);
      formData.append('student_id', this.form.student_id);
      formData.append('package_payment_id', this.form.package_payment_id);
      formData.append('file_type_id', this.form.file_type_id);
      formData.append('marketing_remark', this.form.marketing_remark);

      this.request(`${this.$API.STUDENTS + this.form.student_id}/${this.$API.FILES}`, 'post', formData, () => {
        this.fireSuccess('File uploaded successfully!');
        this.$refs.vtable.init();
        this.cancelUpload();
      });
    },
    cancelUpload() {
      this.uploading = false;
      this.form = {
        package_payment_id: null,
        file_type_id: 3,
        student_id: null,
        title: null,
        file: null,
        marketing_remark: null,
      };
    },
    onLinkFile(item) {
      const studentId = item.package.programme.student_id;
      this.request(`${this.$API.STUDENTS + studentId}/${this.$API.FILES}?per_page=1000`, 'get', null, ({ data }) => {
        this.files = data.data.map((x) => ({
          name: `${x.title} | ${x.file_type.name}`,
          id: x.file_id,
        }));

        if (!this.files.length) {
          this.fireError('This student has no files uploaded');
        } else {
          this.linkFileForm.package_payment_id = item.package_payment_id;
          this.linking = true;
        }
      });
    },
    onUnlinkFile(item) {
      this.request(`${this.$API.PACKAGES + this.$route.params.id}/payments/${item.package_payment_id}`, 'put', {
        file_id: null,
      }, () => {
        this.fireSuccess('File unlinked succesfully');
        this.$refs.vtable.init();
      });
    },
    cancelLinking() {
      this.linking = false;
      this.linkFileForm = {
        package_payment_id: null,
        file_id: null,
        marketing_remark: null,
      };
    },
    linkFile() {
      this.request(`${this.$API.PACKAGES + this.$route.params.id}/payments/${this.linkFileForm.package_payment_id}`, 'put', this.linkFileForm, () => {
        this.fireSuccess('File linked succesfully');
        this.cancelLinking();
        this.$refs.vtable.init();
      });
    },
    onApprovePayment(item) {
      this.approvePaymentDetails = item;
    },
    onDenyPayment(item) {
      this.fireConfirm(
        'Denying Payment',
        'Are you sure you want to deny this payment?',
      ).then(() => {
        this.request(`${this.$API.SCHEDULED_PAYMENTS + item.package_payment_id}/deny`, 'post', null, () => {
          this.$refs.vtable.init();
          this.fireSuccess('Payment denied successfully!');
        });
      });
    },
    onShowPaymentDetails(item) {
      this.selPaymentDetails = item;
    },
  },
};
</script>

<style scoped>
.md-card-header .title {
  margin-bottom: 1em;
}
.md-card-header .subtitle li {
  margin: 0;
}
.md-card-header .subtitle li a {
  font-weight: bold;
  color: #9c27b0 !important;
}
</style>
