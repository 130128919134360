<template>
  <ValidationObserver ref="form">
    <vue-modal
      ref="modal"
      size="md"
      @close="$emit('close')"
    >
      <div slot="title">
        Approve Payment Form
      </div>
      <div slot="body">
        <!-- Spinner -->

        <div class="md-layout">
          <div class="md-layout-item  md-size-33">
            <form-text
              v-model="result.package.programme.student.student_number"
              name="studentNo"
              label="Student Number"
              icon="fingerprint"
              disabled
            />
          </div>
          <div class="md-layout-item  md-size-33">
            <form-text
              v-model="result.package.programme.student.first_name"
              name="first_name"
              label="Given Name"
              icon="face"
              disabled
            />
          </div>
          <div class="md-layout-item  md-size-33">
            <form-text
              v-model="result.package.programme.student.last_name"
              name="last_name"
              label="Surname"
              icon="face"
              disabled
            />
          </div>
          <div class="md-layout-item md-size-50">
            <form-date
              v-model="result.payment_date"
              name="expecteddate"
              label="Expected Date"
              disabled
            />
          </div>
          <div class="md-layout-item md-size-50">
            <form-number
              v-model="result.total"
              name="amount"
              label="Amount to Pay"
              icon="euro"
              disabled
            />
          </div>
        </div>

        <md-card v-if="uploading">
          <md-card-header>
            <h4
              class="title"
              style="font-weight:bold"
            >
              Upload Form
            </h4>

            <ul class="subtitle">
              <li>
                Files size cannot be greater than 5MB. You can reduce the file size <a
                  href="https://www.adobe.com/ie/acrobat/online/compress-pdf.html"
                  target="_blank"
                >HERE</a>
              </li>
              <li>
                Files extension must be <b>.pdf / .jpg / .jpeg / .png</b>
              </li>
            </ul>
          </md-card-header>

          <md-card-content>
            <div class="md-layout">
              <div class="md-layout-item">
                <form-text
                  v-model="form.title"
                  name="title"
                  icon="description"
                  label="File Title"
                  :rules="uploading ? 'required': ''"
                />
              </div>
              <div class="md-layout-item pt-3">
                <form-uploader
                  v-model="form.file"
                  :max-size="5*1024"
                  @error="onFileError"
                >
                  <md-button
                    class="md-info md-sm w-100"
                  >
                    Choose File
                  </md-button>
                  <p
                    v-if="form.file"
                    class="small m-0"
                  >
                    {{ form.file.name }}
                  </p>
                </form-uploader>
              </div>
              <div class="md-layout-item md-size-100">
                <form-text
                  v-model="form.marketing_remark"
                  name="remark"
                  icon="description"
                  label="Receipt Remark"
                  :rules="uploading ? 'required': ''"
                />
              </div>
            </div>
          </md-card-content>
        </md-card>
        <md-card v-if="linking">
          <md-card-header>
            <h4
              class="title"
              style="font-weight:bold"
            >
              Link to File Form
            </h4>
          </md-card-header>

          <md-card-content>
            <div class="md-layout">
              <div class="md-layout-item">
                <form-select
                  v-model="linkFileForm.file_id"
                  :options="files"
                  name="file"
                  icon="attach_file"
                  label="File to Link"
                  :rules="linking ? 'required': ''"
                />
              </div>
              <div class="md-layout-item md-size-100">
                <form-text
                  v-model="linkFileForm.marketing_remark"
                  name="remark"
                  icon="description"
                  label="Receipt Remark"
                  :rules="linking ? 'required': ''"
                />
              </div>
            </div>
          </md-card-content>
        </md-card>
        <hr>
        <div class="md-layout">
          <div class="md-layout-item  md-size-50">
            <md-button
              class="md-primary md-sm w-100"
              @click="onUpload"
            >
              <md-icon class="material-icons">
                cloud_upload
              </md-icon>
              Upload File
            </md-button>
          </div>
          <div class="md-layout-item md-size-50">
            <md-button
              class="md-primary md-sm w-100"
              @click="onLinkFile"
            >
              <md-icon class="material-icons">
                link
              </md-icon>
              Link Payment to File
            </md-button>
          </div>
        </div>
        <hr>

        <div
          v-if="!isSigned"
          class="md-layout"
        >
          <div class="md-layout-item">
            <div class="alert alert-danger text-center font-weight-bold">
              Student didn't approve the Terms & Conditions, please re-send the SignRequest
            </div>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item">
            <form-text
              v-model="form.financial_remark"
              name="remark"
              icon="description"
              label="Payment Remark"
              :disabled="!isSigned"
            />
          </div>
          <div class="md-layout-item">
            <PaymentMethodsSelect
              v-model="form.payment_method_id"
              :disabled="!isSigned"
            />
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item">
            <form-date
              v-model="form.final_payment_date"
              name="final_date"
              label="Payment Date"
              rules="required"
              :disabled="!isSigned"
            />
          </div>
          <div class="md-layout-item">
            <form-number
              v-model="form.total"
              name="amountReceived"
              label="Amount Received"
              icon="euro"
              :disabled="!isSigned"
            />
          </div>
          <div class="md-layout-item">
            <form-number
              v-model="form.bank_fee"
              name="bank_fee"
              icon="euro"
              label="Deducted Fee"
              :disabled="!isSigned"
            />
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item">
            <form-textarea
              v-model="form.payment_comments"
              label="Payment Comments"
              :disabled="!isSigned"
            />
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item">
            <form-textarea
              v-model="form.receipt_comments"
              label="Student Receipt Comments"
              :disabled="!isSigned"
            />
          </div>
        </div>
      </div>
      <div slot="footer">
        <md-button
          v-if="result.proof_of_payment_id"
          class="md-danger mr-2"
          @click="onDownload"
        >
          <md-icon class="mr-2">
            picture_as_pdf
          </md-icon>
          Download Proof of Payment
        </md-button>

        <md-button
          class="md-success"
          :disabled="!isSigned"
          @click="savePayment"
        >
          <md-icon class="mr-2">
            save
          </md-icon>
          Approve
        </md-button>
      </div>
    </vue-modal>
  </ValidationObserver>
</template>

<script>
// eslint-disable-next-line camelcase
import {
  FormText,
  FormNumber,
  FormDate,
  FormSelect,
  FormTextarea,
  FormUploader,
} from '@/components/Inputs';
import { PaymentMethodsSelect } from '@/components/Inputs/selects';
import downloadFile from '@/utils/downloadFile';

import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', required);

export default {
  components: {
    FormText,
    FormNumber,
    FormDate,
    FormSelect,
    FormTextarea,
    FormUploader,
    PaymentMethodsSelect,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    loading: false,
    result: {},
    form: {
      package_payment_id: null,
      file_type_id: 3,
      student_id: null,
      title: null,
      file: null,
      marketing_remark: null,
    },
    uploading: false,
    linking: false,
    linkFileForm: {
      package_payment_id: null,
      file_id: null,
      marketing_remark: null,
    },
    files: null,
  }),
  computed: {
    isSigned() {
      return this.result.package.programme.is_signed;
    },
  },
  created() {
    this.result = { ...this.item };
    this.form.total = this.result.total;
    this.form.payment_method_id = this.result.payment_method_id;
  },
  methods: {
    savePayment() {
      return this.$refs.form.validate().then((res) => {
        if (res) {
          this.fireConfirm(
            'Approving payment',
            'Are you sure all the details are correct?',
          )
            .then(() => {
              if (this.AutoReceiptActive) {
                this.fireConfirm(
                  'Sending Receipt Email',
                  'Do you want to send the Receipt Email?',
                )
                  .then(() => {
                    this.approvePayment(this.item.package_payment_id, true);
                  })
                  .catch(() => {
                    this.approvePayment(this.item.package_payment_id, false);
                  });
              } else {
                this.approvePayment(this.item.package_payment_id, false);
              }
            });
        }
      });
    },
    approvePayment(id, with_email = false) {
      this.loading = true;
      this.request(`${this.$API.SCHEDULED_PAYMENTS + id}/approve`, 'post', {
        ...this.form,
        with_email,
      }, () => {
        if (this.uploading) {
          this.uploadForm();
        } else if (this.linking) {
          this.linkFile();
        }

        this.fireSuccess('Payment Approved succesfully');
        this.$emit('onSave');
        this.$refs.modal.close();
      }, () => {
        this.loading = false;
      });
    },
    onUpload() {
      this.form.package_payment_id = this.result.package_payment_id;
      this.form.student_id = this.result.package.programme.student_id;
      this.uploading = true;
      this.linking = false;
    },
    cancelUpload() {
      this.uploading = false;
      this.form = {
        package_payment_id: null,
        file_type_id: 3,
        student_id: null,
        title: null,
        file: null,
        marketing_remark: null,
      };
    },
    onFileError(error) {
      this.fireError(error);
    },
    uploadForm() {
      const formData = new FormData();
      formData.append('file', this.form.file);
      formData.append('title', this.form.title);
      formData.append('student_id', this.form.student_id);
      formData.append('package_payment_id', this.form.package_payment_id);
      formData.append('file_type_id', this.form.file_type_id);
      formData.append('marketing_remark', this.form.marketing_remark);
      this.request(`${this.$API.STUDENTS + this.form.student_id}/${this.$API.FILES}`, 'post', formData, () => {
        this.fireSuccess('File uploaded successfully!');
        this.$emit('onSave');
        this.cancelUpload();
      });
    },
    onLinkFile() {
      const studentId = this.result.package.programme.student_id;
      this.request(`${this.$API.STUDENTS + studentId}/${this.$API.FILES}?per_page=1000`, 'get', null, ({ data }) => {
        this.files = data.data.map((x) => ({
          name: `${x.title} | ${x.file_type.name}`,
          id: x.file_id,
        }));

        if (!this.files.length) {
          this.fireError('This student has no files uploaded');
        } else {
          this.linkFileForm.package_payment_id = this.result.package_payment_id;
          this.linking = true;
          this.uploading = false;
        }
      });
    },
    cancelLinking() {
      this.linking = false;
      this.linkFileForm = {
        package_payment_id: null,
        file_id: null,
        marketing_remark: null,
      };
    },
    linkFile() {
      this.request(`${this.$API.PACKAGES + this.result.package_id}/payments/${this.linkFileForm.package_payment_id}`, 'put', this.linkFileForm, () => {
        this.fireSuccess('File linked succesfully');
        this.$emit('onSave');
        this.cancelLinking();
      });
    },
    onDownload() {
      const studId = this.result.package.programme.student_id;
      const fileId = this.result.proof_of_payment_id;

      this.request(`${this.$API.STUDENTS + studId}/${this.$API.FILES}${fileId}`,
        'download',
        null,
        ({ data }) => {
          let filename = this.result.file.path.split('/');
          filename = filename[filename.length - 1];
          downloadFile(data, filename);
        });
    },
  },
};
</script>

<style>
</style>
