<template>
  <div
    class="studentData"
  >
    <h4 class="m-0">
      <b>Student Details</b>
    </h4>
    <div class="data-row">
      <div><b>Name: </b> {{ name }}</div>
      <div><b>Number: </b> {{ number }}</div>
      <div><b>Email: </b> {{ email }}</div>
      <div v-if="agent">
        <b>Agent: </b> {{ agent }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: null,
    },
    number: {
      type: String,
      default: null,
    },
    email: {
      type: String,
      default: null,
    },
    agent: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.studentData {
  text-align: center;
  border-top: 1px solid #ccc;
  padding: 10px 0;
  margin-bottom: 30px;
  border-bottom: 1px solid #ccc;

  .data-row {
    display: flex;
    justify-content: space-around;
  }
}
</style>
