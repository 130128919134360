<template>
  <vue-modal
    ref="modal"
    size="md"
    @close="$emit('close')"
  >
    <div slot="title">
      Payment Details
    </div>
    <div slot="body">
      <div class="md-layout">
        <div class="md-layout-item  md-size-33">
          <form-text
            v-model="result.package.programme.student.student_number"
            name="studentNo"
            label="Student Number"
            icon="fingerprint"
            disabled
          />
        </div>
        <div class="md-layout-item  md-size-33">
          <form-text
            v-model="result.package.programme.student.first_name"
            name="first_name"
            label="Given Name"
            icon="face"
            disabled
          />
        </div>
        <div class="md-layout-item  md-size-33">
          <form-text
            v-model="result.package.programme.student.last_name"
            name="last_name"
            label="Surname"
            icon="face"
            disabled
          />
        </div>
        <div class="md-layout-item md-size-50">
          <form-date
            v-model="result.payment_date"
            name="expecteddate"
            label="Expected Date"
            disabled
          />
        </div>
        <div class="md-layout-item md-size-50">
          <form-number
            v-model="result.total"
            name="amount"
            label="Amount to Pay"
            icon="euro"
            disabled
          />
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item">
          <form-text
            v-model="result.marketing_remark"
            name="remark"
            icon="description"
            label="Sales Remark"
            disabled
          />
        </div>
      </div>

      <hr>

      <div class="md-layout">
        <div class="md-layout-item">
          <form-text
            :value="result.approved_by ? result.approved_by.name : '-'"
            label="Approved By"
            icon="verified"
            disabled
          />
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item">
          <form-text
            v-model="result.financial_remark"
            name="remark"
            icon="description"
            label="Payment Remark"
            disabled
          />
        </div>
        <div class="md-layout-item">
          <form-text
            :value="result.payment_method ? result.payment_method.name : '-'"
            icon="payment"
            label="Payment Method"
            disabled
          />
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item">
          <form-date
            v-model="result.final_payment_date"
            name="final_date"
            label="Payment Date"
            disabled
          />
        </div>
        <div class="md-layout-item">
          <form-number
            v-model="result.total"
            name="amountReceived"
            label="Amount Received"
            icon="euro"
            disabled
          />
        </div>
        <div class="md-layout-item">
          <form-number
            v-model="result.bank_fee"
            name="bank_fee"
            icon="euro"
            label="Deducted Fee"
            disabled
          />
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item">
          <form-textarea
            v-model="result.payment_comments"
            label="Payment Comments"
            disabled
          />
          <form-textarea
            v-model="result.receipt_comments"
            label="Student Receipt Comments"
            disabled
          />
        </div>
      </div>
    </div>
    <div slot="footer">
      <md-button
        v-if="result.proof_of_payment_id"
        class="md-danger"
        @click="downloadFile"
      >
        <md-icon class="mr-2">
          picture_as_pdf
        </md-icon>
        Download Proof of Payment
      </md-button>
    </div>
  </vue-modal>
</template>

<script>
import {
  FormText,
  FormNumber,
  FormDate,
  FormTextarea,
} from '@/components/Inputs';

export default {
  components: {
    FormText,
    FormNumber,
    FormTextarea,
    FormDate,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    result: {},
    form: {},
  }),
  created() {
    this.result = { ...this.item };

    // SET BANK FEE IF EXISTS
    if (this.item.package.items.length) {
      const bankFee = this.item.package.items.filter((x) => x.package_item_id === 100)[0];
      if (bankFee) this.result.bank_fee = bankFee.package_package_item.price;
    }
  },
  methods: {
    downloadFile() {
      this.openFileUrl(this.result.proof_of_payment_id);
    },
  },
};
</script>

<style>
</style>
